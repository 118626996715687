<template>
  <div class="container round px-0">
    <div class="page-header mb-1">
      <strong>ตั้งค่างวดหวย</strong>
    </div>
    <div class="card">
      <RoundCardHeader />
      <RoundSetting :data="round" v-if="tabActive==='setting'" @reload="getRound" />
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2'

import LottoService from '@/services/lottoService'
import RoundSetting from './components/RoundSetting'
import RoundCardHeader from './components/RoundCardHeader'

export default {
  name: 'LottoSettings',
  components: {
    RoundSetting,
    RoundCardHeader
  },
  data() {
    return {
      round: null,
      tabActive: 'setting',
      cssStatusClass: {
        bg: null,
        text: null,
        link: null,
        btn: null
      }
    }
  },
  computed: {
    roundId() {
      return this.$store.state.globalMarket.round.roundId
    }
  },
  watch: {
    roundId() {
      console.log('roundId', this.roundId)
      if(this.roundId) {
        this.getRound()
      }
    }
  },
  methods: {
    getRound() {
      LottoService.getRound(this.roundId)
      .then((response)=>{
        console.log('response', response)
        if(response.success) {
          this.round = response.data
        }else{
          throw new Error(response?.message || JSON.stringify(response?.data))
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: (e?.message || e?.data) || 'กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        .then(()=>{
          this.$router.push({name: 'MarketDashboard'})
        })
      })
    },
    setStatusStyle(css) {
      this.cssStatusClass = {
        ...this.cssStatusClass,
        ...css
      }
    }
  },
  mounted() {
    console.log('!mounted')
    this.getRound()
  }
}
</script>
<style lang="scss" scoped>
.round {
  .round-header {
    // display: flex;
    // align-items: flex-end;
  }

  .badge-status {
    font-size: 100%;
  }
}
</style>
